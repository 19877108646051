// @ts-nocheck
import {Image} from "react-vant";
import image from "./bg.c9c3ad4.png"
export default function App() {
    return <Image src={image} onClick={() => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json, text/plain, */*");
        myHeaders.append("Accept-Language", "zh-CN,zh;q=0.9");
        myHeaders.append("Cache-Control", "no-store");
        myHeaders.append("Connection", "keep-alive");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "qtoken=c65578cd-5451-400b-a184-31d33b4c3dfc");
        myHeaders.append("Origin", "https://pro.jvda.com");
        myHeaders.append("Pragma", "no-cache");
        myHeaders.append("Referer", "https://pro.jvda.com/10U/1.0/design/QRTool/pages/pagelXmV93c8T1/edit/custom/Groovy11");
        myHeaders.append("Sec-Fetch-Dest", "empty");
        myHeaders.append("Sec-Fetch-Mode", "cors");
        myHeaders.append("Sec-Fetch-Site", "same-origin");
        myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36");
        myHeaders.append("User-Run", "true");
        myHeaders.append("env", "release");
        myHeaders.append("saasEnv", "A");
        myHeaders.append("sec-ch-ua", "\"Not/A)Brand\";v=\"8\", \"Chromium\";v=\"126\", \"Google Chrome\";v=\"126\"");
        myHeaders.append("sec-ch-ua-mobile", "?0");
        myHeaders.append("sec-ch-ua-platform", "\"Windows\"");

        const raw = JSON.stringify({
            "actionNameEn": "Groovy11",
            "isRunApi": true,
            "actionNameCn": "微信公众号获取签名",
            "appVersion": "latest",
            "appNameEn": "QRTool",
            "isSaas": false,
            "organizationCode": "10U",
            "appToken": "q+0LxvnPWhInsNR4h6oBXH9DcTGsyvU9l/TSj3Q3z7t4bP9DmIKLdNZDEGo4R4bfFzgWY76uqZMiMqVvYIZr29vxkuVTxhHWq7dAZL2Qsmrk2ebrvNh3jJLWb6HgN4vRzL1Ltfec8LEKSscmmQwzUWsHXlXNgRO/w0c3GDx19Koi6tfcOg2uYGiUzU+eK0+tcUJ4xcqm/icIJFA1RJZPkNfWU/7wrp53dfs5SG6j8SechZ83pfJz22Q8OHrkAwAdoG089sId6BvJnvybE+mUXQ==",
            "params": [],
            "viewMode": false
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        fetch("https://pro.jvda.com/qvsu/action/run?n=Groovy11&t=1718877784964", requestOptions)
            .then((response) => response.text())
            .then((res) => {
                const result = JSON.parse(res)
                alert(res)
                alert(result["data"]["body"][0])
                wx.config({ //通过config接口注入权限验证配置
                    appId: result["data"]["body"][0].appid,
                    timestamp: result["data"]["body"][0].timestamp,
                    nonceStr: result["data"]["body"][0].nonceStr,
                    signature: result["data"]["body"][0].signature,
                    jsApiList: ['scanQRCode'] //需要使用的JS接口列表，这里是微信的扫一扫
                })
                wx.ready(() => { //通过ready接口处理成功验证
                    wx.scanQRCode({
                        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                        success: function (res) {
                            alert(JSON.stringify(resultStr))
                        },
                        error: function (error) {
                           alert(JSON.stringify(error))
                        }
                    })
                })
                wx.error(res => {
                    alert(JSON.stringify(res))
                })
            })
            .catch((error) => console.error(error));
    }}></Image>
}
